import React from 'react';
import get from 'lodash.get';

import { Spacing, Container } from '../kenra-storybook/index';
import { injectIntl } from 'gatsby-plugin-react-intl';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const AccessibilityPage = ({ page }) => {
    return (
        <>
            <Spacing removeSpaceBottom>
                <Container>
                    {documentToReactComponents(JSON.parse(get(page, 'text.raw')) || '{}')}
                </Container>
            </Spacing>
        </>
    );
};

export default injectIntl(AccessibilityPage);
