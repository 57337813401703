import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Accessibility from 'components/Accessibility';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function AccessibilityPage(props) {
    let {
        data: { contentfulPage },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulPage, 'seoListing.seoTitle')}
                slug={'accessibility'}
            />
            <Accessibility page={contentfulPage} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulPage(
            title: { eq: "Accessibility" }
            node_locale: { eq: $locale }
        ) {
            ...ContentfulPageFields
        }
    }
`;
